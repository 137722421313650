/**
 * Loadest - CustomDialog stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.CustomDialog {
    min-width: 240px !important;
    text-align: center;
}

.CustomDialog h1 {
    display: block;
    text-align: center;
    font-size: 24px;
    font-family: Open Sans;
    margin-top: 20px;
}