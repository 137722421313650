/**
 * Loadest - DomainsTable stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.DomainsTable .center{
    margin: 0px auto !important;
}

.DomainsTable .icon{
    font-size:24px;
    padding:10px;
    cursor:pointer;
}

.DomainsTable .emptyState{
    font-size:48px;
    padding:10px;
}

.DomainsTable thead th{
    text-align: center;
}

.DomainsTable tbody td{
    text-align: left !important;
}

.DomainsTable table{
    border: #eee 1px solid;
}

.DomainsTable table td {
    border-bottom: #eee 1px solid !important;
}

.DomainsTable table th {
    border-bottom: #eee 1px solid !important;
    padding: 12px !important;
}

.DomainsTable table td:nth-child(3) {
    text-align: center !important;
}

.DomainsTable table td:nth-child(5) {
    text-align: center !important;
    width: 50px;
    white-space: nowrap;
}

.DomainsTable .iconBtn {
    vertical-align: middle;
}

.DomainsTable .iIcon {
    font-size: 12px !important;
    padding: 9px !important;
}


.DomainsTable .searchInput{
    padding: 5px;
    margin-left: 15px;
    margin-top: -55px;
    margin-bottom: 25px;
    width: 40%;
    max-width: 310px;
    float: right
}

.DomainsTable .schedule{
    float: left;
    margin-right: 10px;
    padding-left: 0px;
}

.DomainsTable .card-plain{
    margin-bottom: 0px;
}

.DomainsTable .card .header {
    padding: 15px 0px 0;
}

.DomainsTable .notFound{
    text-align: center;
}

.DomainsTable .materialIconBtn {
    margin: 0px;
    width: 33px;
    text-align: center;
    color: #666;
    line-height: 0.9;
    display: inline-block;
    font-size: 14px;
}

.DomainsTable .iconBtn{
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    border: #ddd 1px solid;
    background: #fff;
    color: #333;
    padding: 7px;
    margin: 3px;
    -webkit-transition: .4s ease-out;
    -webkit-transition-delay: 0.01s;
    -o-transition: .4s ease-out;
    -o-transition-delay: 0.01s;
    -moz-transition: .4s ease-out;
    -moz-transition-delay: 0.01s;
    transition: .4s ease-out;
    transition-delay: 0.01s;
}

.DomainsTable .iconBtn:hover{
    background: #3bc6ca;
    cursor: pointer;
    color: #fff;
    border: #3bc6ca 1px solid;
}

.DomainsTable .active{
    background: #ffc904 !important;
    color: #fff;
    border: #ffc904 1px solid !important;
}

.DomainsTable .active:hover{
    background: #3bc6ca !important;
    cursor: pointer;
    color: #fff;
    border: #3bc6ca 1px solid !important;
}

.DomainsTable .closeBtn:hover {
    background: #fc727a !important;
    border: #ff3333 1px solid !important;
}

.DomainsTable td {
    height: 70px;
}


@media (max-width: 600px) {
    .DomainsTable .card  .header {
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: 20px;
    }

    .DomainsTable td {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
        height: auto;
    }
}