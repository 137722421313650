/**
 * Loadest - Email confirmation page stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.confirmationPage .getStarted {
    padding-top: 30px;
    text-align: center;
}

.confirmationPage .loader {
    margin-top: 28px;
    margin-bottom: 10px;
}

.confirmationPage form {
    margin: 20px;
}

.confirmationPage input[type=text] {
    min-width: 200px;
}