/**
 * Loadest - AdminNavbar stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.AdminNavbar{
    padding-top: 8px;
    padding-bottom: 8px;
}

.AdminNavbar .fa{
    font-family: "FontAwesome";
    font-style: normal;
}

.AdminNavbar .navbar-brand{
    padding-left: 25px !important;
    padding-right: 20px !important;
}

.AdminNavbar .logoutBtn a {
    padding-bottom: 0px !important;
}

.AdminNavbar .exitIcon {
    font-size: 21px;
    margin: 0px;
    margin-bottom: 5px;
}

.AdminNavbar .exitText {
    float: right;
    padding-left: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "Roboto";
    padding-top: 1px;
    font-weight: bold;
}

.AdminNavbar .mobileLogo {
    width: 40px;
    margin-left: 20px;
    float: left;
    display: none;
    margin-top: 9px;
}


@media (max-width: 1360px) {
    .AdminNavbar .mobileLogo {
        display: block;
    }
    .AdminNavbar .navbar-brand {
        padding-left: 35px !important;
    }
}

@media (max-width: 768px) {
    .AdminNavbar .navbar-brand {
        padding-left: 15px !important;
    }
}

