/**
 * Loadest - ApiPage stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.ApiPage h1 {
    font-family: Open Sans;
    font-size: 34px;
    padding-bottom: 40px;
    font-weight: 300;
    display: block;
    text-align: center;
}

.ApiPage h2 {
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
}

.ApiPage .pageHeader {
    padding-bottom: 200px;
}