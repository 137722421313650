.TermsPage h1 {
    font-family: Open Sans;
    font-size: 34px;
    padding-bottom: 40px;
    font-weight: 300;
    display: block;
    text-align: center;
}

.TermsPage h2 {
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
}

.TermsPage .pageHeader {
    padding-bottom: 200px;
}