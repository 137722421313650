/**
 * Loadest - DAO stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.DAO {
    padding: 10px;
}

.DAO li {
    font-size: 16px;
}