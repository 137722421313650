/**
 * Loadest - Admin stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.wrapper{
    background: linear-gradient(90deg, #eee, #ccc);
}

.siteAbout {
    color: #444;
}