/**
 * Loadest - Sidebar stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.sidebar .logo{
    height: 77px;
    padding: 20px;
}

.sidebar .logo{
    padding-top: 12px !important;
    vertical-align: top;
    padding-left: 32px !important;
}

.sidebar .logo-normal{
    padding-top: 10px !important;
    padding-left: 40px !important;
}
