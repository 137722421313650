/**
 * Loadest - Stop page stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.restorePage .getStarted {
    padding-top: 30px;
    text-align: center;
}

.restorePage .loader {
    margin-top: 28px;
    margin-bottom: 10px;
}

.restorePage form {
    margin: 20px;
}