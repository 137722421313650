/**
 * Loadest - TestingListComponent stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.TestingList{
    padding: 10px;
}

.TestingList .content {
    padding-bottom: 50px !important;
}

.TestingList .hidable {
    display: none;
}

.react .TestingList .newTest {
    background: rgb(255,201,4);
    box-shadow: inset 0px 0px 10px 10px rgba(238,170,12,0.3);
}

.react .TestingList .newTest:disabled {
    box-shadow: none !important;
}


@media (max-width: 900px) {
    .TestingList .fullscreen{
        display: none;
    }
    .TestingList .hidable{
        display: table-cell !important;
    }
    .TestingList thead {
        display: none;
    }

    .TestingList .data {
        padding: 0px;
        margin: 0px;
    }

    .TestingList .mobileData {
        padding: 0px !important;
    }

    .react .TestingList .data {
        padding: 0px;
        zoom: 0.7;
    }
}