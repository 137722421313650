/**
 * Loadest - TemplatesTable stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.TemplatesTable .center{
    margin: 0px auto !important;
}

.TemplatesTable .icon{
    font-size:24px;
    padding:10px;
    cursor:pointer;
}

.TemplatesTable .emptyState{
    font-size:48px;
    padding:10px;
}

.TemplatesTable thead th{
    text-align: center;
}

.TemplatesTable tbody td{
    text-align: left !important;
}

.TemplatesTable .searchInput{
    padding: 5px;
    margin-left: 15px;
    margin-top: -55px;
    margin-bottom: 25px;
    width: 40%;
    max-width: 310px;
    float: right
}

.TemplatesTable .schedule{
    float: left;
    margin-right: 10px;
    padding-left: 0px;
}

.TemplatesTable .card-plain{
    margin-bottom: 0px;
}

.TemplatesTable .notFound{
    text-align: center;
}

.TemplatesTable thead th{
    text-align: center;
}

.TemplatesTable tbody td{
    text-align: left !important;
}

.TemplatesTable table{
    border: #eee 1px solid;
}

.TemplatesTable table td {
    border-bottom: #eee 1px solid !important;
}

.TemplatesTable table th {
    border-bottom: #eee 1px solid !important;
    padding: 12px !important;
}

.TemplatesTable table td {
    width: auto; /* 50px; */
}

.TemplatesTable table td:nth-child(1) {
    width: auto !important;
    text-align: left !important;
}

.TemplatesTable table td:nth-child(2) {
    width: auto !important;
    text-align: left !important;
}

.TemplatesTable table td:nth-child(3) {
    width: 80px;
}

.TemplatesTable table td:nth-child(7) {
    white-space: nowrap;
    width: 80px;
}

.TemplatesTable .iconBtn {
    vertical-align: middle;
}

.TemplatesTable .iIcon {
    font-size: 12px !important;
    padding: 9px !important;
}


.TemplatesTable .searchInput{
    padding: 5px;
    margin-left: 15px;
    margin-top: -55px;
    margin-bottom: 25px;
    width: 40%;
    max-width: 310px;
    float: right
}

.TemplatesTable .schedule{
    float: left;
    margin-right: 10px;
    padding-left: 0px;
}

.TemplatesTable .card-plain{
    margin-bottom: 0px;
}

.TemplatesTable .notFound{
    text-align: center;
}

.TemplatesTable .materialIconBtn {
    margin: 0px;
    width: 33px;
    text-align: center;
    color: #666;
    line-height: 0.9;
    display: inline-block;
    font-size: 14px;
}

.TemplatesTable .iconBtn{
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    border: #ddd 1px solid;
    background: #fff;
    color: #333;
    padding: 7px;
    margin: 3px;
    -webkit-transition: .4s ease-out;
    -webkit-transition-delay: 0.01s;
    -o-transition: .4s ease-out;
    -o-transition-delay: 0.01s;
    -moz-transition: .4s ease-out;
    -moz-transition-delay: 0.01s;
    transition: .4s ease-out;
    transition-delay: 0.01s;
}

.TemplatesTable .iconBtn:hover{
    background: #3bc6ca;
    cursor: pointer;
    color: #fff;
    border: #3bc6ca 1px solid;
}

.TemplatesTable .active{
    background: #ffc904 !important;
    color: #fff;
    border: #ffc904 1px solid !important;
}

.TemplatesTable .active:hover{
    background: #3bc6ca !important;
    cursor: pointer;
    color: #fff;
    border: #3bc6ca 1px solid !important;
}

.TemplatesTable .closeBtn:hover {
    background: #fc727a !important;
    border: #ff3333 1px solid !important;
}

.TemplatesTable .btn {
    padding-left: 12px;
    padding-right: 14px;
    -webkit-transition: .4s ease-out;
    -webkit-transition-delay: 0.01s;
    -o-transition: .4s ease-out;
    -o-transition-delay: 0.01s;
    -moz-transition: .4s ease-out;
    -moz-transition-delay: 0.01s;
    transition: .4s ease-out;
    transition-delay: 0.01s;
    height: 42px;
}

.TemplatesTable .btn:hover {
    box-shadow: none !important;
}

.TemplatesTable .btn:hover .btnText {
    display: inline-block;
}

.TemplatesTable .card .header {
    padding: 15px 0px 0;
}

.TemplatesTable .newTemplate {
    margin: 0px;
    position: absolute;
    right: 20px;
    top: 15px;
    float: none;
}

.TemplatesTable .table-container {
    overflow: auto;

}

@media (max-width: 600px) {
    .TemplatesTable .card  .header {
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: 20px;
    }

    .TemplatesTable .newTemplate {
        left: 30px;
        position: absolute;
        z-index: 999;
        zoom: 1;
        top: 37px;
        right: auto;
        width: auto;
        max-width: 185px;
    }

    .TemplatesTable .table-container {
        margin-top: 35px
    }

    .TemplatesTable td {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }
}