/**
 * Loadest - TestingsTable stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.TestingsTable .center{
    margin: 0px auto !important;
}

.TestingsTable .icon{
    font-size:24px;
    padding:10px;
    cursor:pointer;
}

.TestingsTable .emptyState{
    font-size:48px;
    padding:10px;
}

.TestingsTable thead th{
    text-align: center;
}

.TestingsTable tbody td{
    text-align: left !important;
}

.TestingsTable .searchInput{
    padding: 5px;
    margin-left: 15px;
    margin-top: -132px;
    margin-bottom: 25px;
    width: 40%;
    max-width: 310px;
    float: right
}

.TestingsTable .schedule{
    float: left;
    margin-right: 10px;
    padding-left: 0px;
}

.TestingsTable .card-plain{
    margin-bottom: 0px;
}

.TestingsTable .notFound{
    text-align: center;
}

.TestingsTable .icons {
    width: 17px;
    height: 17px;
    color: #FFFFFF;
}

.TestingsTable .btn {
    padding-left: 12px;
    padding-right: 14px;
    -webkit-transition: .4s ease-out;
    -webkit-transition-delay: 0.01s;
    -o-transition: .4s ease-out;
    -o-transition-delay: 0.01s;
    -moz-transition: .4s ease-out;
    -moz-transition-delay: 0.01s;
    transition: .4s ease-out;
    transition-delay: 0.01s;
    height: 42px;
}

.TestingsTable .btn:hover {
    background: #3bc6ca;
}

.TestingsTable .btn:hover .btnText {
    display: inline-block;
}

.TestingsTable .details {
    border-top: #c0c0c0 0px solid;
    padding: 0px;
}

.TestingsTable .details:first-child {
    padding-top: 10px;
}

.TestingsTable .details .data {
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    margin-top: 0px;
    margin-bottom: 25px;
    border: #ddd 1px dotted;
}

.TestingsTable .details td {
    border-top: 0px solid;
    padding: 0px;
    margin: 0px;
}

.TestingsTable .details .panel {
    padding: 0px;
    margin: 0px;
    border-radius: 5px;
    border: #ddd 0px dotted;
}

.TestingsTable .details .chart {
    max-height: 400px;
    padding-bottom: 40px;
    width: 100%;
    overflow: hidden;
    -webkit-transition: .4s ease-out;
    -webkit-transition-delay: 0.01s;
    -o-transition: .4s ease-out;
    -o-transition-delay: 0.01s;
    -moz-transition: .4s ease-out;
    -moz-transition-delay: 0.01s;
    transition: .4s ease-out;
    transition-delay: 0.01s;
}

.TestingsTable table{
    border: #eee 1px solid;
}

.TestingsTable table ::marker {
    color: transparent;
}

.TestingsTable table th {
    border-bottom: #eee 1px solid !important;
    padding: 12px !important;
}

.TestingsTable table td:first-child {
    white-space: nowrap;
}

.TestingsTable table td:nth-child(3) {
    white-space: nowrap;
}

.TestingsTable table td:last-child {
    white-space: nowrap;
    text-align: center !important;
}

.TestingsTable .expanded td {
    border-bottom: 0px solid !important;
}

.TestingsTable .iconBtn {
    vertical-align: middle;
}

.TestingsTable .iIcon {
    font-size: 12px !important;
    padding: 9px !important;
}

.TestingsTable .materialIconBtn {
    margin: 0px;
    width: 33px;
    text-align: center;
    color: #666;
    line-height: 0.9;
    display: inline-block;
    font-size: 14px;
}

.TestingsTable .iconBtn{
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    border: #ddd 1px solid;
    background: #fff;
    color: #333;
    padding: 7px;
    margin: 3px;
    -webkit-transition: .4s ease-out;
    -webkit-transition-delay: 0.01s;
    -o-transition: .4s ease-out;
    -o-transition-delay: 0.01s;
    -moz-transition: .4s ease-out;
    -moz-transition-delay: 0.01s;
    transition: .4s ease-out;
    transition-delay: 0.01s;
}

.TestingsTable .iconBtn:not(.unactive):hover{
    background: #3bc6ca;
    cursor: pointer;
    color: #fff;
    border: #3bc6ca 1px solid;
}

.TestingsTable .active{
    background: #ffc904 !important;
    color: #fff;
    border: #ffc904 1px solid !important;
}

.TestingsTable .active:hover{
    background: #3bc6ca !important;
    cursor: pointer;
    color: #fff;
    border: #3bc6ca 1px solid !important;
}

.TestingsTable .closeBtn:hover {
    background: #fc727a !important;
    border: #ff3333 1px solid !important;
}

.TestingsTable .card .header {
    padding: 15px 0px 0;
}

.TestingsTable .panel-caption {
    font-size: 11px;
    padding: 8px;
    text-transform: uppercase;
    float:left;
}

.TestingsTable .newTest {
    position: absolute;
    right: 20px;
    top: 15px;
    float: none;
}

@media (max-width: 900px) {
    .TestingsTable .long {
        max-width: 500px;
    }

    .react .TestingsTable td,
    .react .TestingsTable td:last-child{
        text-align: left !important;
    }


    .TestingsTable .open .dropdown-menu {
        margin-left: -150px;
    }
}

@media (max-width: 600px) {

    .TestingsTable table td {
        line-height: 1.5;
        padding-top:7px;
        padding-bottom: 7px;
    }
    .TestingsTable table td:last-child {
        white-space: nowrap;
        text-align: left !important;
    }

    .TestingsTable .long {
        max-width: 300px;
    }

    .TestingsTable .card .header {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
    }

    .TestingsTable .testingData {
        margin-top: 45px;
    }

    .TestingsTable .testingData .hidable {
        padding: 7px;
    }

    .TestingsTable .newTest {
        left: 30px;
        position: absolute;
        z-index: 999;
        zoom: 1;
        top: 35px;
        right: auto;
        width: auto;
        max-width: 140px;
    }
    .TestingsTable .details .chart {
        max-height: 350px;
    }
}


@media (max-width: 420px) {
    .TestingsTable .long {
        max-width: 150px;
    }
}