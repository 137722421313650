/**
 * Loadest - TemplateList stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.TemplateList {
    padding: 10px;
}

.TemplateList .newTemplate {
    background: rgb(255,201,4);
    box-shadow: inset 0px 0px 10px 10px rgba(238,170,12,0.3);
}