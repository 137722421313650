@font-face {
    font-family: 'glyphicons-halflings';
    src:url('../fonts/glyphicons-halflings-regular.eot');
    src:url('../fonts/glyphicons-halflings-regular.eot?#iefixd7yf1v') format('embedded-opentype'),
    url('../fonts/glyphicons-halflings-regular.woff?d7yf1v') format('woff'),
    url('../fonts/glyphicons-halflings-regular.ttf?d7yf1v') format('truetype'),
    url('../fonts/glyphicons-halflings-regular.svg?d7yf1v#Pe-icon-7-stroke') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}