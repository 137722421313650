/**
 * Loadest - ErrorsTable stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.ErrorsTable .center{
    margin: 0px auto !important;
}

.ErrorsTable .icon{
    font-size:24px;
    padding:10px;
    cursor:pointer;
}

.ErrorsTable .emptyState{
    font-size:48px;
    padding:10px;
}

.ErrorsTable thead th{
    text-align: center;
}

.ErrorsTable tbody td{
    text-align: left !important;
}

.ErrorsTable .searchInput{
    padding: 5px;
    margin-left: 15px;
    margin-top: -100px;
    margin-bottom: 25px;
    width: 40%;
    max-width: 310px;
    float: right
}

.ErrorsTable .schedule{
    float: left;
    margin-right: 10px;
    padding-left: 0px;
}

.ErrorsTable .card-plain{
    margin-bottom: 0px;
}

.ErrorsTable .notFound{
    text-align: center;
}

.ErrorsTable .materialIconBtn {
    margin: 0px;
    width: 33px;
    text-align: center;
    color: #666;
    line-height: 0.9;
    display: inline-block;
    font-size: 14px;
}

.ErrorsTable .iconBtn{
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    border: #ddd 1px solid;
    background: #fff;
    color: #333;
    padding: 7px;
    margin: 3px;
    -webkit-transition: .4s ease-out;
    -webkit-transition-delay: 0.01s;
    -o-transition: .4s ease-out;
    -o-transition-delay: 0.01s;
    -moz-transition: .4s ease-out;
    -moz-transition-delay: 0.01s;
    transition: .4s ease-out;
    transition-delay: 0.01s;
}

.ErrorsTable .iconBtn:hover{
    background: #3bc6ca;
    cursor: pointer;
    color: #fff;
    border: #3bc6ca 1px solid;
}


.ErrorsTable .btn {
    padding-left: 12px;
    padding-right: 8px;
    -webkit-transition: .4s ease-out;
    -webkit-transition-delay: 0.01s;
    -o-transition: .4s ease-out;
    -o-transition-delay: 0.01s;
    -moz-transition: .4s ease-out;
    -moz-transition-delay: 0.01s;
    transition: .4s ease-out;
    transition-delay: 0.01s;
    height: 42px;
}

.ErrorsTable .btn:hover {
    background: #3bc6ca;
}

.ErrorsTable .btn:hover .btnText {
    display: inline-block;
}


.ErrorsTable .btn:hover {
    box-shadow: none !important;
}

.ErrorsTable .btn:hover .btnText {
    display: inline-block;
}

.ErrorsTable .newTest {
    right: 20px;
    top: 15px;
    width: auto;
    position: absolute;
    z-index: 10;

}

@media (max-width: 600px) {
    .ErrorsTable .newTest {
        top: 5px !important;
    }
}