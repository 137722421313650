/**
 * Loadest - Dashboard stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.dashboard .card-plain{
    margin-bottom: 0px;
}

.dashboard .card .header {
    padding: 15px 0px 0;
}

.dashboard .h4{
    padding: 10px;
    padding-left: 30px;
}

.dashboard .btn {
    padding-left: 12px;
    padding-right: 14px;
    -webkit-transition: .4s ease-out;
    -webkit-transition-delay: 0.01s;
    -o-transition: .4s ease-out;
    -o-transition-delay: 0.01s;
    -moz-transition: .4s ease-out;
    -moz-transition-delay: 0.01s;
    transition: .4s ease-out;
    height: 42px;
}

.dashboard .btn:disabled {
    box-shadow: none !important;
}

.dashboard .newTest:hover {
    background: #3bc6ca;
}

.dashboard .btn:hover .btnText {
    display: inline-block;
}

.dashboard .notFound{
    text-align: center;
}

.dashboard .emptyState{
    font-size:48px;
    padding:10px;
}

.dashboard .data{
    padding: 10px;
    margin: 10px;
    marginTop: 0px;
    border: #efefef 1px dotted;
    height: 500px;
    marginBottom: 25px;
}

.react .dashboard .newTest {
    position: absolute;
    right: 20px;
    background: rgb(255,201,4);
    box-shadow: inset 0px 0px 10px 10px rgba(238,170,12,0.3);
}

.react .dashboard .extend {
    right: 40px;
    top: 30px;
    width: auto;
}

@media (max-width: 600px) {
    .dashboard .data{
        height: 350px;
    }
    .react .dashboard .h4  {
        padding-top: 20px;
    }
    .react .dashboard .data {
        padding: 0px;
        zoom: 0.7;
        margin-top: 85px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .react .dashboard .newTest {
        left: 35px;
        position: absolute;
        z-index: 999;
        zoom: 1;
        top: 40px;
        right: auto;
        max-width: 124px;
        width: auto;
    }
    .react .dashboard .extend {
        top: 63px;
        zoom: 1.45;
        left: 43px;
        right: auto;
        max-width: 140px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .react .dashboard .content {
        padding-bottom: 0px !important;
    }

}