/**
 * Loadest - Application primary stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

#react {
    display: none;
}

#react {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

input, textarea, select{
    border-radius: 3px;
    border: #c0c0c0 1px solid;
    padding: 3px;
    padding-left: 4px;
    font-family: Tahoma, "Roboto", Arial !important;
    color: #333;
    -webkit-appearance: none !important;
    -webkit-font-smoothing: antialiased !important;
    background: #fff;
}

.react input,
.react textarea,
.react select {
    font-family: Tahoma, "Roboto", Arial !important;;
    -webkit-appearance: none !important;
    -webkit-font-smoothing: antialiased !important;
}

.react .selectable {
    user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -o-user-select: text;
}

input::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
    color: #c0c0c0;
    opacity: 1;
    filter: alpha(opacity=100);
}

input:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder {
    color: #c0c0c0;
    opacity: 1;
    filter: alpha(opacity=100);
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
    color: #c0c0c0;
    opacity: 1;
    filter: alpha(opacity=100);
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
    color: #c0c0c0;
    opacity: 1;
    filter: alpha(opacity=100);
}

.MuiInputBase-input {
    padding-left: 5px !important;
}

.link{
    color: #3bc6ca;
    cursor: pointer;
}

.main-panel{
    /* background: linear-gradient(90deg, rgb(238, 238, 238), rgb(204, 204, 204)) !important; */
    background: transparent !important;
}

.spinner{
    text-align: center;
}

.MuiCheckbox-root{
    background: transparent !important;
}

.MuiSwitch-colorSecondary{
    background: transparent !important;
}

.wrapper {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0;
    bottom: 0px;
    overflow: hidden;
    overflow-y: scroll;
    background: linear-gradient(90deg, #eee, #ccc);
}

.loader {
    width: 100%;
    margin: 0px auto;
    text-align: center;
    margin-top: 79px;
    margin-bottom: 79px;
    min-height: 130px;
}

.notFound {
    padding-top: 70px;
    padding-bottom: 80px;
}

.sc-launcher {
    background: #ffc904 !important;
}

.sc-header{
    background: #333 !important;
}

.opened {
    background: #333 !important;
}

.sc-header--team-name {
    color: #fff !important;
}

.sc-chat-window {
    z-index: 1;
}

.sc-message .sent .sc-message--text {
    background: #fff !important;
    color: #333 !important;;
}

.sc-header--close-button:hover {
    background: transparent !important;
}

.sc-launcher{
    zoom: 0.8;
    right: 40px !important;
    z-index: 3;
}

.sc-launcher img {
    right: 40px !important;
}

.sc-chat-window {
    right: 35px;
}

.sc-launcher .sc-closed-icon {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    cursor: pointer;
}

.sc-launcher:hover {
    background: #3bc6ca !important;
}

.MuiButton-label {
    font-family: "Roboto";
    font-size: 12px;
}

.siteLogo {
    padding-top: 5px;
}

.siteName {
    float: right;
    padding-left: 5px;
    margin-top: -3px;
    font-size: 16px;
}

.siteIcon {
    font-size: 18px;
}

.siteAbout {
    color: #fff;
    margin: 10px;
    margin-left: 0px;
}

.h100 {
    height: 100px;
}

.h50 {
    height: 50px;
}

.h20 {
    height: 20px;
}

.MuiInputLabel-animated {
    padding-left: 7px !important;
}

.MuiInputAdornment-positionEnd {
    margin-left: 12px !important;
    margin-right: 10px !important;
}

.pageHeader {
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
}

.card .content .content {
    min-height: 320px !important;
}

.app-loader {
   margin-top: 75px;
    margin-bottom: 75px;
    padding: 40px;
}

.table td {
    border-color: #eee !important;
}

.react .MuiDialog-paper {
    margin: 15px;
}

.react .actBtn {
    float: right;
    margin-top: -55px;
    position: absolute;
    right: 20px;
}

.react .MuiButton-root.Mui-disabled {
    opacity: 0.7;
}

.react .cryto {
    text-align: center;
    padding-bottom: 120px;
    padding-top: 50px;
}

@media (max-width: 1024px) {
    .react .siteAbout {
        display: none;
    }
}

@media (max-width: 600px) {
    .react .content {
        padding: 0px;
        padding-top: 0px;
        padding-bottom: 20px !important;
        overflow: hidden;
    }
    .react .content .container-fluid {
        padding-top: 10px;
        padding-left: 10px;
    }
    .react .card {
        margin-bottom: 5px;
    }
    .react .actBtn {
        margin-top: -15px;
        zoom: 0.7;
    }
    .react .cryto {
        text-align: center;
        padding-bottom: 50px;
        padding-top: 0px;
    }
}