.DetailsPage h1 {
    font-family: Open Sans;
    font-size: 34px;
    padding-bottom: 40px;
    font-weight: 300;
    display: block;
    text-align: center;
}

.DetailsPage h2 {
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
}

.DetailsPage .h4{
    padding: 10px;
    padding-left: 30px;
}

.DetailsPage .pageHeader {
    padding-bottom: 200px;
}

.DetailsPage .loader {
    padding-top: 62px;
    padding-bottom: 50px;
}