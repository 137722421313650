/**
 * Loadest - RightModal stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.modalBackground{
    background: rgba(0,0,0,0.3);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
}

.RightModal{
    background: #fff;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.05), 0px 0px 0px 1px rgba(63,63,68,0.1);
    overflow: hidden;
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    left: calc(50% + 65px);
    z-index: 3;
}

.RightModal .header{
    background: linear-gradient(135deg, #444, #333);
    width: 100%;
    padding: 25px;
    color: #fff;
    font-size: 18px;
    font-family: "Roboto";
    font-weight: 300;
}

.RightModal .modal_content{
    text-align: center;
    width: 100%;
}

.RightModal .property{
    border: #c0c0c0 1px solid;
    text-align: left;
    padding: 20px;
    margin: 20px;
    margin-top: 30px;
    border-radius: 7px;
}

.RightModal .property_name{
    padding: 10px;
    background: #fff;
    margin-top: -40px;
    display: table;
}

.RightModal .property_details{
    padding: 10px;
}

.RightModal .required{
    color: #e11;
    padding: 3px;
    font-weight: bold;
}

.RightModal .backToDefault{
    width: 30px;
    padding: 2px;
    margin-top: -3px;
    margin-left: 2px;
}

.RightModal .backToDefault i{
    font-weight: bold;
}

.RightModal .withoutButton{
    width: 100%;
}

.RightModal .withButton{
    width: calc(100% - 32px);
}

.RightModal .tabs{
    display: table;
}

.RightModal .tab{
    display: table-cell;
    padding: 10px;
    padding-top: 12px;
    padding-right: 25px;
    padding-left: 25px;
    cursor: pointer;
}

.RightModal .choosen{
    border-bottom: #42d0ed 4px solid;
}

.RightModal .modal_details{
    border-top: #c0c0c0 1px solid;
    border-bottom: #c0c0c0 1px solid;
    padding: 5px;
    overflow: hidden;
    overflow-y:auto;
    margin-bottom: 15px;
    height: calc(100% - 150px);
    margin-top: 0px;
}

.RightModal .saveButton{
    margin-left: 15px;
}

.RightModal input{
    padding: 3px;
    width: 100%;
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.RightModal select{
    width: 100%;
    padding: 7px;
}

.RightModal textarea{
    width: 100%;
    padding: 7px;
}

.RightModal .h10 {
    height: 10px;
}

.RightModal .infoBlock {
    margin: 20px;
    border: #c0c0c0 1px dotted;
    text-align: left;
    line-height: 2.0;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
}


@media (max-width: 800px) {
    .RightModal{
        left: 0px;
    }
}
