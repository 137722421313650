/**
 * Loadest - Signup page stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.signupPage .loader {
    margin-top: 123px;
    margin-bottom: 123px;
}

.signupPage .conditions {
    color: rgb(102, 102, 102);
    padding-top: 20px;
    padding-bottom: 10px;
    font-family: "Roboto";
    font-size: 14px;
}

.signupPage .getStarted {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
}

.signupPage .getStarted button {
    margin: 0px auto;
}

.signupPage form {
    margin: 15px;
}