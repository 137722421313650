/**
 * Loadest - Landing Page stylesheets.
 *
 * 1.3.0 # Aleksandr Vorkunov <devbyzero@yandex.ru>
 */

.landingPage {
    background: rgba(246,246,246,1);
}

.landingPage .siteAbout {
    color: #333;
}

.landingPage h2 {
    color: rgb(34, 34, 34);
    font-size: 28px;
    margin-top: 30px;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;
    letter-spacing: 0.5px;
    line-height: 1.2;
    font-family: "Open Sans";
}

.landingPage h3{
    color: rgb(34, 34, 34);
    font-size: 28px;
    margin-top: 0px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 40px;
    letter-spacing: 0.5px;
    line-height: 1.2;
    font-family: "Open Sans";
    font-weight: 300;
    display: block;
}

.landingPage .membership {
    border: rgb(222, 222, 222) 1px solid;
    padding: 20px;
    box-shadow: 0 8px 12px 1px rgb(0 0 0 / 7%), 0 3px 15px 2.5px rgb(0 0 0 / 6%), 0 4px 5px -2.5px rgb(0 0 0 / 10%);
    border-radius: 6px;
    font: 16px Verdana;
    color: #000;
    width: 100%;
    text-align: center;
    background: #fff;
    padding-bottom: 35px;
    margin-bottom: 30px
}

.landingPage .membership b {
    font-size: 18px;
    font-family: "Open Sans";
    text-align: center;
    width: 100%;
    display: block;
    font-weight: 300;
    text-transform: uppercase;
    border-bottom: rgb(222, 222, 222) 1px solid;
    padding-bottom: 25px;
    padding-top: 10px;
}

.landingPage .membership ul {
    text-align: left;
    padding-top: 10px;
}

.landingPage .membership li {
    line-height: 1.5;
    padding-bottom: 10px;
    font-family: "Roboto";
    list-style: none;
    margin-left: -30px;
}

.landingPage .membership strong {
    display: block;
    text-align: center;
    font-size: 32px;
    word-spacing: 3px;
    color: #3bc6ca;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 300;
}

.landingPage .membership .MuiButton-label {
    color: #331604;
}

.landingPage .landingBlock {
    position: absolute;
    top: 190px;
    width: 450px;
    left: calc(50% - 225px);
    padding: 30px;
    padding-bottom: 40px;
    text-align: center;
    background: rgba(0,0,0,0.6);
    border-radius: 3px;
}

.landingPage .landingBlock h1 {
    font-size: 46px;
}

@media (max-width: 600px) {
    .landingPage .landingBlock {
        zoom: 0.7;
        width: auto;
        left: 0px;
        right: 0px;
        top: 280px;
        padding-top: 40px;
        padding-bottom: 60px;
    }
    .landingPage .landingBlock h1 {
        font-size: 38px;
    }
    .landingPage h2,
    .landingPage h3 {
        font-size: 21px;
    }
}

@media (max-width: 340px) {
    .landingPage .landingBlock h1 {
        font-size: 32px;
    }
}